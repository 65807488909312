import { GET_ENUMS, SET_LOADING } from "../actions/types";

const initialState = {
  collections: {},
  loading: false,
  error: null,
};

const enumReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ENUMS:
      const processedDataByLocale = action.payload.data?.reduce((acc, item) => {
        const locale = item.locale || "all"; // Use 'all' if locale field is missing?
        if (!acc[locale]) {
          acc[locale] = [];
        }
        acc[locale].push(item);
        return acc;
      }, {});
      // Example: processedDataByLocale = { en: [...], es: [...] }

      // Ensure state.collections exists before spreading
      const currentCollections = state.collections || {};
      const currentCollectionData =
        currentCollections[action.payload.collectionName] || {};

      return {
        ...state,
        collections: {
          // Spread the potentially defaulted currentCollections
          ...currentCollections,
          [action.payload.collectionName]: {
            // Spread the potentially defaulted currentCollectionData
            ...currentCollectionData,
            // Add the newly processed locale data
            ...processedDataByLocale,
          },
        },
        loading: false,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
};

export default enumReducer;
