import {
  GET_ARTICLES,
  GET_ARTICLE,
  SET_FILTERED_ARTICLES,
  CLEAR_FILTERED_ARTICLES,
  SET_LOADING,
  GENERATE_MEXICAN_WINE_FACTS,
  SET_FACTS_LOADING,
  ADD_PENDING_COMMENT,
  COMMENT_SUCCESS,
  COMMENT_FAILURE,
  SUBMITTING_COMMENT,
} from "../actions/types";

const initialState = {
  articles: [],
  article: {},
  typeFilter: null,
  loading: false,
  error: null,
  facts: [],
  factsLoading: false,
  comments: [],
  commentError: null,
  pendingComments: [],
  submittingComment: false,
};

const articleReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ARTICLE:
      return {
        ...state,
        article: action.payload,
        pendingComments: [],
        comments: [...action.payload.comments],
        loading: false,
      };
    case GET_ARTICLES:
      return {
        ...state,
        articles: action.payload,
        loading: false,
      };
    case SET_FILTERED_ARTICLES:
      return {
        ...state,
        typeFilter: action.typeFilter,
        loading: false,
      };
    case CLEAR_FILTERED_ARTICLES:
      return {
        ...state,
        typeFilter: null,
        loading: false,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GENERATE_MEXICAN_WINE_FACTS:
      return {
        ...state,
        facts: {
          ...state.facts,
          [action.locale]: [...action.payload.facts],
        },
        factsLoading: false,
      };
    case SET_FACTS_LOADING:
      return {
        ...state,
        factsLoading: true,
      };
    case ADD_PENDING_COMMENT:
      return {
        ...state,
        pendingComments: [action.payload, ...state.pendingComments],
        submittingComment: false,
        commentError: null,
      };

    case COMMENT_SUCCESS:
      return {
        ...state,
        // Add the real comment to regular comments
        comments: [action.payload.comment, ...state.comments],
        article: state.article && {
          ...state.article,
          comments: [action.payload.comment, ...(state.article.comments || [])],
        },
        // Remove the pending comment by tempId/nameDate
        pendingComments: state.pendingComments.filter(
          (comment) => comment.nameDate !== action.payload.comment.nameDate
        ),
        commentError: null,
        submittingComment: false,
      };

    case COMMENT_FAILURE:
      return {
        ...state,
        // Since we may not have a tempId for moderation failures
        // (since they happen before optimistic updates),
        // we conditionally filter pending comments only if tempId exists
        pendingComments: action.payload.nameDate
          ? state.pendingComments.filter(
              (comment) => comment.nameDate !== action.payload.nameDate
            )
          : state.pendingComments,
        commentError: action.payload.error,
        submittingComment: false,
      };
    case SUBMITTING_COMMENT:
      return {
        ...state,
        submittingComment: true,
      };
    default:
      return state;
  }
};

export default articleReducer;
