import React, { lazy } from "react";

//ROUTER
import { Route, Routes } from "react-router-dom";

//LEGAL SCREENS
import TermsOfService from "../screens/LegalScreens/TermsOfService";
import CookiePolicy from "../screens/LegalScreens/CookiePolicy";
import PrivacyPolicy from "../screens/LegalScreens/PrivacyPolicy";

//404
import FourOfour from "../screens/FourOfour";

//PUBLIC SCREENS
const HomeScreen = lazy(() => import("../screens/HomeScreen"));
const WineriesScreen = lazy(() => import("../screens/WineriesScreen"));
const WineryScreen = lazy(() => import("../screens/WineryScreen"));
const ArticlesScreen = lazy(() => import("../screens/ArticlesScreen"));
const ArticleScreen = lazy(() => import("../screens/ArticleScreen"));
const AppRoutes = () => (
  <Routes>
    {/* Public Screens */}
    <Route path="/" element={<HomeScreen />} />
    <Route exact path="/wineries" element={<WineriesScreen />} />
    <Route path="/wineries/:wineryId" element={<WineryScreen />} />
    <Route exact path="/wines" element={<HomeScreen />} />
    <Route path="/wines/:wineId" element={<HomeScreen />} />
    <Route path="/articles" element={<ArticlesScreen />} />
    <Route path="/articles/:articleId" element={<ArticleScreen />} />
    <Route path="/terms-of-service" element={<TermsOfService />} />
    <Route path="/cookie-policy" element={<CookiePolicy />} />
    <Route path="/privacy-policy" element={<PrivacyPolicy />} />

    {/* 404 Page */}
    <Route path="*" element={<FourOfour />} />
  </Routes>
);

export default AppRoutes;
