export const GET_WINERIES = "GET_WINERIES";
export const GET_WINERIES_ERROR = "GET_WINERIES_ERROR";
export const GET_HOME_WINERIES = "GET_HOME_WINERIES";
export const GET_WINERY = "GET_WINERY";
export const CLEAR_WINERY = "CLEAR_WINERY";
export const GET_WINES = "GET_WINES";
export const GET_WINE = "GET_WINE";
export const GET_ARTICLES = "GET_ARTICLES";
export const GET_ARTICLE = "GET_ARTICLE";
export const SEARCH_WINERIES = "SEARCH_WINERIES";
export const SEARCH_WINERIES_ERROR = "SEARCH_WINERIES_ERROR";
export const SEARCH_WINES = "SEARCH_WINES";
export const SEARCH_WINES_ERROR = "SEARCH_WINES_ERROR";
export const CLEAR_SEARCHED_WINERIES = "CLEAR_FILTERED_WINERIES";
export const SET_LOADING = "SET_LOADING";
export const CLEAR_FILTERED_WINES = "CLEAR_FILTERED_WINES";
export const CLEAR_SEARCHED_WINES = "CLEAR_FILTERED_WINES";
export const SET_FILTERED_WINES = "SET_FILTERED_WINES";
export const CLEAR_FILTERED_ARTICLES = "CLEAR_FILTERED_ARTICLES";
export const SET_FILTERED_ARTICLES = "SET_FILTERED_ARTICLES";
export const GET_ENUMS = "GET_ENUMS";
export const GENERATE_MEXICAN_WINE_FACTS = "GENERATE_MEXICAN_WINE_FACTS";
export const SET_FACTS_LOADING = "SET_FACTS_LOADING";
export const ADD_PENDING_COMMENT = "ADD_PENDING_COMMENT";
export const COMMENT_SUCCESS = "COMMENT_SUCCESS";
export const COMMENT_FAILURE = "COMMENT_FAILURE";
export const SUBMITTING_COMMENT = "SUBMITTING_COMMENT";
//USER CONSTANTS
export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";

export const USER_REGISTER_REQUEST = "USER_REGISTER_REQUEST";
export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS";
export const USER_REGISTER_FAIL = "USER_REGISTER_FAIL";

export const USER_LOGOUT = "USER_LOGOUT";
export const USER_DETAILS_RESET = "USER_DETAILS_RESET";

//PRODUCERS FORM CONSTANTS
export const FORM_SEND_REQUEST = "FORM_SEND_REQUEST";
export const FORM_SEND_SUCCESS = "FORM_SEND_SUCCESS";
export const FORM_SEND_FAIL = "FORM_SEND_FAIL";

export const APPEND_WINERIES = "APPEND_WINERIES";
export const APPEND_SEARCH_WINERIES = "APPEND_SEARCH_WINERIES";
